import { HiX } from "react-icons/hi";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import {
  MdOutlineDashboard,
  MdWorkOutline,
  MdOutlineLogout,
  MdOutlineAssignment,
  MdOutlineGroups,
  MdOutlinePersonOutline,
} from "react-icons/md";
import React from "react";
import { UserContext } from "controller/context/userContext";

interface SidebarContainerProps {
  open: boolean;
  toggled: boolean;
  setToggled: () => void;
}

const SidebarContainer: React.FC<SidebarContainerProps> = ({
  open,
  toggled,
  setToggled,
}) => {
  const { logOutUser } = React.useContext(UserContext);
  const location = useLocation();

  // Function to check if the route is active
  const isActiveRoute = (route: string) => location.pathname === route;

  return (
    <Sidebar collapsed={open} toggled={toggled} breakPoint="md">
      <div className="flex h-screen flex-col justify-between bg-white dark:bg-navy-800">
        {/* Sidebar Menu */}
        <div className="mt-4">
          <div className="mb-8 flex items-center px-6">
            <span onClick={setToggled} className="md:hidden">
              <HiX />
            </span>
            {!open && <div className="ml-2 text-2xl font-bold">EDISON</div>}
          </div>
          <Menu>
            <MenuItem
              icon={<MdOutlineDashboard className="h-6 w-6" />}
              className={
                isActiveRoute("/admin/default") ? "text-orange-500" : ""
              }
            >
              <Link to="/admin/default">Dashboard</Link>
            </MenuItem>

            {/* Jobs Submenu */}
            <SubMenu icon={<MdWorkOutline className="h-6 w-6" />} label="Jobs">
              <MenuItem
                className={
                  isActiveRoute("/admin/view-all-jobs") ? "text-orange-500" : ""
                }
              >
                <Link to="/admin/view-all-jobs">View All Jobs</Link>
              </MenuItem>
              <MenuItem
                className={
                  isActiveRoute("/admin/create-job") ? "text-orange-500" : ""
                }
              >
                <Link to="/admin/create-job">Create Job</Link>
              </MenuItem>
            </SubMenu>

            {/* Interview Results Submenu */}
            <SubMenu
              icon={<MdOutlineAssignment className="h-6 w-6" />}
              label="Interview Results"
            >
              <MenuItem
                className={
                  isActiveRoute("/admin/all-results") ? "text-orange-500" : ""
                }
              >
                <Link to="/admin/all-results">All Results</Link>
              </MenuItem>
              <MenuItem
                className={
                  isActiveRoute("/admin/next-round-candidates")
                    ? "text-orange-500"
                    : ""
                }
              >
                <Link to="/admin/next-round-candidates">
                  Next Round Candidates
                </Link>
              </MenuItem>
            </SubMenu>

            {/* Candidate Submenu */}
            <SubMenu
              icon={<MdOutlineGroups className="h-6 w-6" />}
              label="Candidate"
            >
              <MenuItem
                className={
                  isActiveRoute("/admin/create-candidate") ? "text-orange-500" : ""
                }
              >
                <Link to="/admin/create-candidate">Create a Candidate</Link>
              </MenuItem>
              <MenuItem
                className={
                  isActiveRoute("/admin/view-candidates")
                    ? "text-orange-500"
                    : ""
                }
              >
                <Link to="/admin/view-candidates">
                  View All Candidates
                </Link>
              </MenuItem>
            </SubMenu>

            {/* Other Settings Submenu */}
            <SubMenu
              icon={<MdOutlinePersonOutline className="h-6 w-6" />}
              label="Other Settings"
            >
              <MenuItem
                className={
                  isActiveRoute("/admin/pre-screen-questions")
                    ? "text-orange-500"
                    : ""
                }
              >
                <Link to="/admin/pre-screen-questions">
                  Pre-Screen Questions
                </Link>
              </MenuItem>
              <MenuItem
                className={
                  isActiveRoute("/admin/buy-credits") ? "text-orange-500" : ""
                }
              >
                <Link to="/admin/buy-credits">Buy Credits</Link>
              </MenuItem>
            </SubMenu>
          </Menu>
        </div>

        {/* Logout Button */}
        <div className="mb-4 px-6">
          <button
            onClick={logOutUser}
            className="flex w-full items-center justify-center gap-2 rounded-lg bg-orange-600 px-4 py-2 text-white"
          >
            <MdOutlineLogout className="mr-2" />
            Logout
          </button>
        </div>
      </div>
    </Sidebar>
  );
};

export default SidebarContainer;
