import { toast, ToastOptions } from "react-toastify";
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineInfoCircle,
  AiOutlineWarning,
} from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import "./toastStyles.css";

export enum ToastType {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
  WARNING = "warning",
}

export const showToast = (
  message: string,
  type: ToastType,
  options?: ToastOptions
) => {
  let icon;
  switch (type) {
    case ToastType.SUCCESS:
      icon = <AiOutlineCheckCircle size={24} />;
      break;
    case ToastType.ERROR:
      icon = <AiOutlineCloseCircle size={24} />;
      break;
    case ToastType.INFO:
      icon = <AiOutlineInfoCircle size={24} />;
      break;
    case ToastType.WARNING:
      icon = <AiOutlineWarning size={24} />;
      break;
    default:
      icon = <AiOutlineInfoCircle size={24} />;
  }

  toast(message, {
    icon,
    className: `custom-toast ${type}`,
    progressClassName: "custom-progress",
    closeButton: false,
    autoClose: 3000,
    ...options,
  });
};
