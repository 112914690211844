// import { useNavigate } from "react-router-dom";
import { useGetCandidatesByOrganization } from "model/queryCalls/queryCalls";

const ActiveCandidates = ({ organizationId }: { organizationId: string }) => {
  //   const navigate = useNavigate();

  const {
    data: candidates = [],
    isLoading,
    isError,
  } = useGetCandidatesByOrganization(organizationId);

  const handleCandidateClick = (candidateId: string) => {
    // navigate(`/admin/candidate/${candidateId}`);
  };

  if (isLoading) {
    return <div>Loading candidates...</div>;
  }

  if (isError) {
    return <div>Failed to load candidates.</div>;
  }

  return (
    <div className="col-span-1 rounded-lg bg-white p-6 shadow md:col-span-2 xl:col-span-3">
      <h3 className="mb-4 text-xl font-medium">Active Candidates</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-medium uppercase tracking-wider text-gray-500">
                Name
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium uppercase tracking-wider text-gray-500">
                Qualification
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium uppercase tracking-wider text-gray-500">
                Date of Birth
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium uppercase tracking-wider text-gray-500">
                Email
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {candidates.map((candidate: any) => (
              <tr
                key={candidate._id}
                className="cursor-pointer hover:bg-gray-50"
                onClick={() => handleCandidateClick(candidate._id)}
              >
                <td className="text-black-500 whitespace-nowrap px-6 py-4 text-sm">
                  {candidate.name}
                </td>
                <td className="text-black-500 whitespace-nowrap px-6 py-4 text-sm">
                  {candidate.qualification}
                </td>
                <td className="text-black-500 whitespace-nowrap px-6 py-4 text-sm">
                  {new Date(candidate.dateOfBirth).toLocaleDateString()}
                </td>
                <td className="text-black-500 whitespace-nowrap px-6 py-4 text-sm">
                  {candidate.email}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActiveCandidates;
