import * as Realm from "realm-web";
import envConfig from "../../env/env.json";

export const app = new Realm.App({ id: envConfig.MONGODB_APP_ID });

export const {
  BSON: { ObjectId },
} = Realm;
export const getDBInstance = () => {
  if (!app || !app.currentUser) {
    return;
  }
  return app.currentUser.mongoClient("mongodb-atlas").db(envConfig.MONGODB_DB);
};

export const callMongoFunction = async <T>(
  fnName: string,
  payload: object
): Promise<T> => {
  try {
    const response = await app.currentUser.callFunction(fnName, payload);
    return response as T;
  } catch (error: any) {
    console.error(`Error calling ${fnName}:`, error.message);
    throw new Error(`Failed to execute ${fnName}`);
  }
};

