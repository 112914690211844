/*  */
/* User services */
/*  */

import { app, getDBInstance, ObjectId } from "./dbServices";

export const userAddWithRole = async ({ data, roleId }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!data || !roleId) {
    return;
  }
  if (typeof roleId === "string") {
    roleId = new ObjectId(roleId);
  }

  const result = await getDBInstance().collection("users").insertOne({
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    number: data.number,
    createdAt: new Date(),
    updatedAt: null,
    isVerified: false,
    roleId,
  });
  return result;
};

export const userUpdateWithRole = async ({ id, data, roleId }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id || !data || !roleId) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  if (typeof roleId === "string") {
    roleId = new ObjectId(roleId);
  }
  const specificRoleId = new ObjectId("661d7c37952ae5895d13ea85");
  const findResult = await getDBInstance()
    .collection("users")
    .find({ roleId: specificRoleId });
  if (findResult.length === 1) {
    const Result = await getDBInstance()
      .collection("users")
      .findOne({ _id: id });
    if (Result.roleId.toString() === specificRoleId.toString()) {
      return false;
    }
    await getDBInstance()
      .collection("users")
      .updateOne(
        {
          _id: id,
        },
        {
          $set: {
            firstName: data.firstName,
            lastName: data.lastName,
            number: data.number,
            updatedAt: new Date(),
            roleId,
          },
        }
      );

    return true;
  }
  await getDBInstance()
    .collection("users")
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          firstName: data.firstName,
          lastName: data.lastName,
          number: data.number,
          updatedAt: new Date(),
          roleId,
        },
      }
    );

  return true;
};

export const getUsers = async ({ page = 0, rowsPerPage = 10 }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  const totalDocumentCount = await getDBInstance()
    .collection("users")
    .count({});

  const result = await getDBInstance()
    .collection("users")
    .aggregate([
      {
        $match: {},
      },
      { $skip: skip },
      { $limit: limit },
    ]);

  return { data: result, totalDocumentCount };
};

export const getOneUser = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection("users")
    .aggregate([
      {
        $match: { _id: id },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);
  return result[0];
};

export const updateUser = async ({ id, name }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id || !name) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection("users")
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          name: name,
        },
      }
    );

  return result;
};

export const DeleteUser = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection("users")
    .deleteOne({ _id: id });

  return result;
};

export const getCurrentUser = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!id) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection("users")
    .aggregate([
      {
        $match: {
          _id: id,
        },
      },
      {
        $lookup: {
          from: "userRoles",
          localField: "roleId",
          foreignField: "_id",
          as: "role",
        },
      },
      { $unwind: "$role" },
      {
        $project: {
          email: 1,
          firstName: 1,
          lastName: 1,
          number: 1,
          createdAt: 1,
          updatedAt: 1,
          isVerified: 1,
          roleId: 1,
          role: 1,
          permissions: "$role.permissions",
        },
      },
    ]);

  return result[0];
};
