import ActiveJobs from "components/jobsData";
import { UserContext } from "controller/context/userContext";
import React from "react";

const Dashboard = () => {
  const { user } = React.useContext(UserContext);

  return (
    <div className="p-6">
      {/* Header */}
      <div className="mb-6 flex items-center justify-center rounded-lg bg-orange-400 p-8 text-white">
        <h2 className="text-2xl font-semibold">{`${user?.profile?.data?.name}`}</h2>
      </div>

      {/* Occupation Dropdown */}
      <div className="relative mb-8">
        <label className="block text-lg font-medium text-gray-700">
          Occupation
        </label>
        <div className="mt-2 w-full max-w-sm">
          {/* Set max width to limit the length */}
          <select
            className={`block w-full appearance-none rounded-lg border border-gray-300 p-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg`}
            defaultValue=""
          >
            <option value="" disabled hidden>
              Job Opening Name
            </option>
          </select>
        </div>
      </div>

      {/* Applicants Table */}
      <div className="mb-8 overflow-hidden shadow-sm ring-1 ring-gray-200 sm:rounded-lg">
        <ActiveJobs organizationId={"66d8a7b2f8bc7c05c1321944"} />
      </div>

      {/* Stats Section */}
      <div className="grid grid-cols-3 gap-4">
        <div className="rounded-lg bg-white p-4 text-center shadow-md">
          <p className="text-2xl font-bold">50</p>
          <p className="text-sm text-gray-500">Applicants</p>
        </div>
        <div className="rounded-lg bg-white p-4 text-center shadow-md">
          <p className="text-2xl font-bold">120/200</p>
          <p className="text-sm text-gray-500">Attended Interviews</p>
        </div>
        <div className="rounded-lg bg-white p-4 text-center shadow-md">
          <p className="text-2xl font-bold">100-120</p>
          <p className="text-sm text-gray-500">Interview Credits Left</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
