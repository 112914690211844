// Admin Imports
import MainDashboard from "views/admin/default";
import DataTables from "views/admin/tables";

import Users from "views/admin/users";
import AddUsers from "views/admin/users/addUsers";

import AddRoles from "views/admin/roles/addRoles";

// Auth Imports
import SignIn from "views/auth/SignIn";
import ViewAllJobs from "views/admin/jobs/viewAllJobs";
import CreateJob from "views/admin/jobs/createJob";
import AllResults from "views/admin/interviewResults/allResults";
import NextRoundCandidates from "views/admin/interviewResults/nextRoundCandidates";
import BuyCredits from "views/admin/otherSettings/buyCredits";
import PreScreenQuestions from "views/admin/otherSettings/preScreenQuestions";
import CreateCandidate from "views/admin/candidate/createCandidate";
import ViewAllCandidates from "views/admin/candidate/viewAllCandidate";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    component: <MainDashboard />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    component: <SignIn />,
  },
  {
    name: "View All Jobs",
    layout: "/admin",
    path: "view-all-jobs",
    component: <ViewAllJobs />,
  },
  {
    name: "Create Job",
    layout: "/admin",
    path: "create-job",
    component: <CreateJob />,
  },
  {
    name: "Update Job",
    layout: "/admin",
    path: "job/:jobId",
    component: <CreateJob />,
  },
  {
    name: "View All Jobs",
    layout: "/admin",
    path: "all-results",
    component: <AllResults />,
  },
  {
    name: "Create Job",
    layout: "/admin",
    path: "next-round-candidates",
    component: <NextRoundCandidates />,
  },
  {
    name: "Create Candidate",
    layout: "/admin",
    path: "create-candidate",
    component: <CreateCandidate />,
  },
  {
    name: "View All Candidate",
    layout: "/admin",
    path: "view-candidates",
    component: <ViewAllCandidates />,
  },
  {
    name: "View All Jobs",
    layout: "/admin",
    path: "pre-screen-questions",
    component: <PreScreenQuestions />,
  },
  {
    name: "Create Job",
    layout: "/admin",
    path: "buy-credits",
    component: <BuyCredits />,
  },
  {
    name: "Data Tables",
    layout: "/admin",
    path: "data-tables",
    component: <DataTables />,
  },
  {
    name: "All Users",
    layout: "/admin",
    path: "all-users",
    component: <Users />,
  },
  {
    name: "Add Users",
    layout: "/admin",
    path: "add-users",
    component: <AddUsers />,
  },
  {
    name: "Add Roles",
    layout: "/admin",
    path: "add-roles",
    component: <AddRoles />,
  },
];

export default routes;
