import ActiveJobs from "components/jobsData";

const ViewAllJobs = () => {
  return (
    <div className="p-6">
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3">
        <ActiveJobs organizationId={"66d8a7b2f8bc7c05c1321944"} />
      </div>
    </div>
  );
};

export default ViewAllJobs;
