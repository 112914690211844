import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../controller/context/userContext";
import "react-toastify/dist/ReactToastify.css";

const SignIn: React.FC = () => {
  const { googleLogin } = React.useContext(UserContext);
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleGoogleLogin = async () => {
    setError(null);
    setLoading(true);
    try {
      const authUser = await googleLogin();
      if (authUser && authUser.profile.email) {
        navigate("/admin"); 
      }
    } catch (error) {
      setError("Login failed. Please try again.");
      console.error("Login error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen w-full flex-col md:flex-row">
      <div
        className="flex w-full flex-col items-center justify-center p-6 text-white md:w-1/2 md:p-8 lg:p-12"
        style={{ backgroundColor: "#FF7006" }}
      >
        <h1 className="text-center text-3xl font-bold md:text-5xl lg:text-7xl">
          Hello, User!
        </h1>
        <p className="mt-4 text-center text-xl md:text-2xl lg:text-3xl">
          Login to start your journey with us.
        </p>
      </div>

      <div className="flex w-full flex-col items-center justify-center bg-white p-8 md:w-1/2 md:p-12 lg:p-16">
        <h4 className="text-black mb-8 text-center text-2xl font-bold md:text-3xl lg:text-4xl">
          Sign in to Edison AI
        </h4>

        {error && <p className="mb-4 text-center text-red-500">{error}</p>}

        <button
          onClick={handleGoogleLogin}
          className="flex h-12 w-[260px] max-w-full items-center justify-center gap-3 rounded-full border border-gray-300 bg-white px-8 shadow-lg transition-all duration-300 ease-in-out hover:bg-gray-100 hover:shadow-xl md:h-14 md:w-[350px] md:px-10 lg:h-16 lg:w-[450px] lg:px-12"
        >
          {loading ? (
            <span className="text-lg font-medium text-gray-800 md:text-xl lg:text-2xl">
              Logging in...
            </span>
          ) : (
            <>
              <FcGoogle className="text-2xl md:text-3xl lg:text-4xl" />
              <span className="text-lg font-medium text-gray-800 md:text-xl lg:text-2xl">
                Sign in with Google
              </span>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default SignIn;
