import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";

interface FormData {
  jobType: string;
  question: string;
  required: boolean;
}

const PreScreenQuestions: React.FC = () => {
  const { register, handleSubmit } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    console.log("Form Submitted:", data);
  };

  return (
    <div className="rounded-lg bg-white p-8 shadow-lg">
        <h1 className="mb-6 text-2xl font-semibold">Screening Questions</h1>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        <div>
          <label className="block mb-2 text-gray-700 text-lg font-semibold">Choose Job Type</label>
          <select
            {...register("jobType", { required: true })}
            className="block w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FF7006]"
          >
            <option value="" disabled hidden>
              Choose Job Name
            </option>
            <option value="JobType1">Job Type 1</option>
            <option value="JobType2">Job Type 2</option>
          </select>
        </div>

        <div className="border p-4 rounded-md">
          <label className="block mb-2 text-lg text-gray-700 font-semibold">Question</label>
          <textarea
            {...register("question", { required: true })}
            placeholder="Enter the Question"
            className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FF7006]"
            rows={4}
          ></textarea>

          <div className="mt-4 flex items-center">
            <input
              type="checkbox"
              {...register("required")}
              id="required"
              className="h-5 w-5 text-[#FF7006] border-gray-300 rounded focus:ring-2 focus:ring-[#FF7006]"
            />
            <label htmlFor="required" className="ml-2 text-gray-700">
              Required
            </label>
          </div>
        </div>

        <div className="text-center">
          <button
            type="submit"
            className="px-6 py-3 text-lg font-semibold text-white bg-[#FF7006] rounded-full hover:bg-[#e66c05] transition duration-300"
          >
            Add Question
          </button>
        </div>
      </form>
    </div>
  );
};

export default PreScreenQuestions;
