import React, { useContext, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { UserContext } from "controller/context/userContext";
import { JobData } from "types/jobs";
import {
  useCreateJob,
  useGetJobById,
  useUpdateJob,
} from "model/queryCalls/queryCalls";
import { showToast, ToastType } from "components/toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CreateJob = () => {
  const { user } = useContext(UserContext);
  const { jobId } = useParams<{ jobId?: string }>();
  const [loading, setLoading] = useState(false);
  const [lastDateToApply, setLastDateToApply] = useState<Date | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<JobData>();

  const createJobMutation = useCreateJob();

  const { data: jobData, isLoading: isJobLoading } = useGetJobById(jobId || "");

  const updateJobMutation = useUpdateJob();

  useEffect(() => {
    if (jobData) {
      setValue("title", jobData.title);
      setValue("company", jobData.company);
      setValue("workplaceType", jobData.workplaceType);
      setValue("location", jobData.location);
      setValue("jobType", jobData.jobType);
      setValue("description", jobData.description);
      setLastDateToApply(new Date(jobData.lastDateToApply));
    }
  }, [jobData, setValue]);

  const onSubmit: SubmitHandler<JobData> = async (data: JobData) => {
    setLoading(true);
    try {
      if (!lastDateToApply) {
        showToast("Please select the last date to apply.", ToastType.ERROR);
        setLoading(false);
        return;
      }

      const jobPayload: JobData = {
        ...data,
        lastDateToApply,
        metadata: {
          createdBy: user?.profile?.data?.name || "Unknown",
          createdAt: new Date(),
          modifiedBy: null,
          modifiedAt: null,
        },
      };

      if (jobId) {
        await updateJobMutation.mutateAsync({
          jobId,
          jobData: jobPayload,
        });
        showToast("Job updated successfully!", ToastType.SUCCESS);
      } else {
        await createJobMutation.mutateAsync({
          jobData: jobPayload,
        });
        showToast("Job created successfully!", ToastType.SUCCESS);
      }
    } catch (error) {
      showToast(
        jobId
          ? "Failed to update job. Please try again."
          : "Failed to create job. Please try again.",
        ToastType.ERROR
      );
      console.error("Job submission error:", error);
    } finally {
      setLoading(false);
    }
  };

  if (isJobLoading && jobId) {
    return <div>Loading job details...</div>;
  }

  return (
    <div className="p-6">
      <ToastContainer />
      <div className="rounded-lg bg-white p-8 shadow-lg">
        <h1 className="mb-6 text-2xl font-semibold">
          {jobId ? "Edit Job" : "Job Details"}
        </h1>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-cols-2 gap-x-8 gap-y-6"
        >
          <div>
            <label className="block text-lg font-medium text-gray-700">
              Job Title
            </label>
            <input
              type="text"
              {...register("title", { required: "Job title is required" })}
              placeholder="Title"
              className={`mt-2 block w-full rounded-lg border ${
                errors.title ? "border-red-500" : "border-gray-300"
              } p-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FF7006] sm:text-lg`}
      
            />
            {errors.title && (
              <p className="text-sm text-red-500">{errors.title.message}</p>
            )}
          </div>

          <div>
            <label className="block text-lg font-medium text-gray-700">
              Company
            </label>
            <input
              type="text"
              {...register("company", { required: "Company is required" })}
              placeholder="Company"
              className={`mt-2 block w-full rounded-lg border ${
                errors.company ? "border-red-500" : "border-gray-300"
              } p-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FF7006] sm:text-lg`}
            />
            {errors.company && (
              <p className="text-sm text-red-500">{errors.company.message}</p>
            )}
          </div>

          <div className="relative">
            <label className="block text-lg font-medium text-gray-700">
              Workplace Type
            </label>
            <select
              {...register("workplaceType", {
                required: "Workplace type is required",
              })}
              className={`mt-2 block w-full rounded-lg border ${
                errors.workplaceType ? "border-red-500" : "border-gray-300"
              } appearance-none p-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FF7006] sm:text-lg`}
              defaultValue=""
            >
              <option value="" disabled hidden>
                Type
              </option>
              <option value="Remote">Remote</option>
              <option value="On-site">On-site</option>
              <option value="Hybrid">Hybrid</option>
            </select>
            {errors.workplaceType && (
              <p className="text-sm text-red-500">
                {errors.workplaceType.message}
              </p>
            )}
          </div>

          <div className="relative">
            <label className="block text-lg font-medium text-gray-700">
              Job Type
            </label>
            <select
              {...register("jobType", { required: "Job type is required" })}
              className={`mt-2 block w-full rounded-lg border ${
                errors.jobType ? "border-red-500" : "border-gray-300"
              } appearance-none p-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FF7006] sm:text-lg`}
              defaultValue=""
            >
              <option value="" disabled hidden>
                Choose Job Type
              </option>
              <option value="Full-time">Full-time</option>
              <option value="Part-time">Part-time</option>
              <option value="Contract">Contract</option>
            </select>
            {errors.jobType && (
              <p className="text-sm text-red-500">{errors.jobType.message}</p>
            )}
          </div>

          <div>
            <label className="block text-lg font-medium text-gray-700">
              Job Location
            </label>
            <input
              type="text"
              {...register("location", {
                required: "Job location is required",
              })}
              placeholder="Location"
              className={`mt-2 block w-full rounded-lg border ${
                errors.location ? "border-red-500" : "border-gray-300"
              } p-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FF7006] sm:text-lg`}
            />
            {errors.location && (
              <p className="text-sm text-red-500">{errors.location.message}</p>
            )}
          </div>

          <div>
            <label className="block text-lg font-medium text-gray-700">
              Last Date to Apply
            </label>
            <DatePicker
              selected={lastDateToApply}
              onChange={(date: Date | null) => setLastDateToApply(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select date"
              className={`mt-2 block w-full rounded-lg border ${
                !lastDateToApply && errors.lastDateToApply
                  ? "border-red-500"
                  : "border-gray-300"
              } p-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FF7006] sm:text-lg`}
            />
            {!lastDateToApply && (
              <p className="text-sm text-red-500">
                Last Date to Apply is required.
              </p>
            )}
          </div>

          <div className="col-span-2">
            <label className="block text-lg font-medium text-gray-700">
              Description
            </label>
            <textarea
              {...register("description", {
                required: "Job description is required",
              })}
              placeholder="Description"
              rows={5}
              className={`mt-2 block w-full rounded-lg border ${
                errors.description ? "border-red-500" : "border-gray-300"
              } p-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FF7006] sm:text-lg`}
            ></textarea>
            {errors.description && (
              <p className="text-sm text-red-500">
                {errors.description.message}
              </p>
            )}
          </div>

          <div className="col-span-2">
            <button
              type="submit"
              className="mt-4 w-full rounded-lg bg-[#FF7006] p-3 text-lg font-semibold text-white"
              disabled={loading}
            >
              {loading ? "Submitting..." : jobId ? "Update" : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateJob;
