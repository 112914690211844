import { JobData } from "types/jobs";
import { callMongoFunction } from "./dbServices";

// export const createJob = async (
//     jobData: JobData,
//     createdBy: string
//   ): Promise<InsertJobResponse> => {
//     const db = getDBInstance();
//     if (!db) {
//       throw new Error("No database instance available");
//     }

//     const jobsCollection = db.collection("jobs");

//     const newJob: JobData = {
//       ...jobData,
//       organizationId: new ObjectId("66d8a7b2f8bc7c05c1321944"),
//       metadata: {
//         createdAt: new Date(),
//         createdBy,
//         modifiedAt: null,
//         modifiedBy: null,
//       },
//     };

//     const result = await jobsCollection.insertOne(newJob);
//     return { insertedId: result.insertedId.toString() };
//   };

/**
 * Get jobs by organization ID.
 */
export const getJobsByOrganization = async (
  organizationId: string
): Promise<JobData[]> => {
  if (!organizationId) throw new Error("Organization ID required");
  return callMongoFunction<JobData[]>("getJobsByOrganization", {
    organizationId,
  });
};
