import { useMutation, useQuery } from "react-query";
import { useContext } from "react";
import { UserContext } from "controller/context/userContext";
import { getOneUser, getUsers } from "controller/services/userServices";
import { JobData } from "types/jobs";
import { getJobsByOrganization } from "controller/services/jobServices";
import { callMongoFunction } from "controller/services/dbServices";
import { CandidateData } from "types/candidate";

/**
 * Hook to create a new job
 */
export const useCreateJob = () => {
  return useMutation(async ({ jobData }: { jobData: JobData }) => {
    return await callMongoFunction("createJob", { ...jobData });
  });
};

/**
 * Hook to update a job
 */
export const useUpdateJob = () => {
  return useMutation(
    async ({ jobId, jobData }: { jobId: string; jobData: JobData }) => {
      return await callMongoFunction("updateJobById", { jobId, jobData });
    }
  );
};

/**
 * Hook to create a new Candidate
 */
export const useCreateCandidate = () => {
  return useMutation(async (candidateData: CandidateData) => {
    return await callMongoFunction("createCandidate", candidateData);
  });
};

/**
 * Hook to fetch candidates by organization.
 */
export const useGetCandidatesByOrganization = (organizationId: string) => {
  return useQuery<CandidateData[]>(
    ["candidatesData", organizationId],
    async (): Promise<CandidateData[]> => {
      const response = await callMongoFunction<CandidateData[]>("getCandidatesByOrganization", { organizationId });
      return response;
    },
    {
      onError: (error: unknown) => {
        console.error("Failed to fetch candidates:", error);
      },
    }
  );
};

/**
 * Hook to fetch jobs by organization ID.
 */
export const useGetJobsByOrganization = (organizationId: string) => {
  return useQuery(
    ["jobs", organizationId],
    () => getJobsByOrganization(organizationId),
    {
      enabled: !!organizationId,
      onError: (error: unknown) => {
        console.error("Failed to fetch jobs:", error);
      },
    }
  );
};
/**
 * Hook to fetch a job by ID
 */
export const useGetJobById = (jobId: string) => {
  return useQuery<JobData>(
    ["job", jobId],
    () => callMongoFunction<JobData>("getJobById", { jobId }),
    {
      enabled: !!jobId,
    }
  );
};

/**
 * Hook to fetch the users list.
 */
export const useUsersList = ({
  page,
  rowsPerPage,
}: {
  page: number;
  rowsPerPage: number;
}) => {
  const { user } = useContext(UserContext);
  return useQuery(
    ["usersData", user, page, rowsPerPage],
    () => getUsers({ page, rowsPerPage }),
    {
      onError: (error: unknown) => {
        console.error("Failed to fetch users:", error);
      },
    }
  );
};

/**
 * Hook to fetch details of a single user by ID.
 */
export const useOneUser = ({ id }: { id: string }) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneUser", user, { id }], () => getOneUser({ id }), {
    enabled: !!id,
    onError: (error: unknown) => {
      console.error("Failed to fetch user details:", error);
    },
  });
};
