import React, { useContext, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { showToast, ToastType } from "components/toast";
import { useCreateCandidate } from "model/queryCalls/queryCalls"; // Your react-query hook for creating a candidate
import { UserContext } from "controller/context/userContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CandidateData } from "types/candidate";

const CreateCandidate = () => {
  const { user } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CandidateData>();

  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);

  const createCandidateMutation = useCreateCandidate();

  const onSubmit: SubmitHandler<CandidateData> = async (
    data: CandidateData
  ) => {
    setLoading(true);

    if (!dateOfBirth) {
      showToast("Please select a valid Date of Birth", ToastType.ERROR);
      setLoading(false);
      return;
    }

    try {
      const candidateData: CandidateData = {
        ...data,
        dateOfBirth,
        organizationId: "66d8a7b2f8bc7c05c1321944",
        metadata: {
          createdBy: user?.profile?.data?.name || "Unknown",
          createdAt: new Date(),
          modifiedBy: null,
          modifiedAt: null,
        },
      };

      await createCandidateMutation.mutateAsync(candidateData);
      showToast("Candidate created successfully!", ToastType.SUCCESS);
    } catch (error) {
      showToast(
        "Failed to create candidate. Please try again.",
        ToastType.ERROR
      );
      console.error("Candidate creation error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6">
      <ToastContainer />
      <div className="rounded-lg bg-white p-8 shadow-lg">
        <h1 className="mb-6 text-2xl font-semibold">Create Candidate</h1>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-cols-2 gap-x-8 gap-y-6"
        >
          {/* Name */}
          <div>
            <label className="block text-lg font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              {...register("name", { required: "Candidate name is required" })}
              placeholder="Name"
              className={`mt-2 block w-full rounded-lg border ${
                errors.name ? "border-red-500" : "border-gray-300"
              } p-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FF7006] sm:text-lg`}
            />
            {errors.name && (
              <p className="text-sm text-red-500">{errors.name.message}</p>
            )}
          </div>

          {/* Email */}
          <div>
            <label className="block text-lg font-medium text-gray-700">
              Email
            </label>
            <input
              type="text"
              {...register("email", { required: "Email is required" })}
              placeholder="Email"
              className={`mt-2 block w-full rounded-lg border ${
                errors.email ? "border-red-500" : "border-gray-300"
              } p-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FF7006] sm:text-lg`}
            />
            {errors.email && (
              <p className="text-sm text-red-500">{errors.email.message}</p>
            )}
          </div>

          {/* Qualification */}
          <div>
            <label className="block text-lg font-medium text-gray-700">
              Qualification
            </label>
            <input
              type="text"
              {...register("qualification", {
                required: "Qualification is required",
              })}
              placeholder="Qualification"
              className={`mt-2 block w-full rounded-lg border ${
                errors.qualification ? "border-red-500" : "border-gray-300"
              } p-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FF7006] sm:text-lg`}
            />
            {errors.qualification && (
              <p className="text-sm text-red-500">
                {errors.qualification.message}
              </p>
            )}
          </div>

          {/* Date of Birth with DatePicker */}
          <div>
            <label className="block text-lg font-medium text-gray-700">
              Date of Birth
            </label>
            <DatePicker
              selected={dateOfBirth}
              onChange={(date: Date | null) => setDateOfBirth(date)}
              placeholderText="Select Date of Birth"
              className={`mt-2 block w-full rounded-lg border ${
                !dateOfBirth && errors.dateOfBirth
                  ? "border-red-500"
                  : "border-gray-300"
              } p-3 shadow-sm focus:border-[#FF7006] focus:ring-[#FF7006] sm:text-lg`}
            />

            {!dateOfBirth && (
              <p className="text-sm text-red-500">Date of Birth is required.</p>
            )}
          </div>

          {/* Submit Button */}
          <div className="col-span-2">
            <button
              type="submit"
              className="mt-4 w-full rounded-lg bg-[#FF7006] p-3 text-lg font-semibold text-white"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateCandidate;
